<template>
  <student-form
    title="Alamat"
    :is-visible="isVisible"
    @update-triggered="triggerUpdate()"
  >
    <template #goto-prev-form-trigger>
      <button
        class="button --primary uppercase text-white tracking-wider"
        @click="triggerUpdate('core')"
      >
        <fa-icon icon="arrow-left"></fa-icon>
        <span class="ml-2">Formulir Sebelumnya</span>
      </button>
    </template>

    <h1 class="text-xl text-center uppercase tracking-wider">Data ALAMAT</h1>

    <!-- form -->
    <form action="#" @submit.prevent="triggerUpdate()">
      <!-- Address -->
      <label class="form-label" for="address_addres">Alamat</label>
      <input type="text" id="address_address" v-model="form.address" />

      <!-- RT -->
      <label class="form-label" for="address_rt">RT</label>
      <input type="text" id="address_rt" v-model="form.rt" />

      <!-- RW -->
      <label class="form-label" for="address_rw">RW</label>
      <input type="text" id="address_rw" v-model="form.rw" />

      <!-- distric -->
      <span class="form-label">Kecamatan</span>
      <div
        v-for="(district, key) in choices.districts"
        :key="`district-${key}`"
        class="mb-1"
      >
        <input
          :id="'subdistrict-' + key"
          type="radio"
          name="subdistrict"
          :value="district"
          v-model="form.district"
        />
        <label
          class="ml-2 uppercase tracking-wider"
          :for="'subdistrict-' + key"
          >{{ district }}</label
        >
      </div>

      <!-- subdistrict -->
      <label class="mt-4 block form-label" for="address_subdistrict"
        >Desa/kelurahan</label
      >
      <input type="text" id="address_subdistrict" v-model="form.subdistrict" />

      <!-- live with -->
      <span class="mt-4 form-label">Tempat tinggal</span>
      <div
        class="mb-1.5"
        v-for="(home, key) in choices.liveWith"
        :key="`home-${key}`"
      >
        <input
          :id="'live-with-' + key"
          type="radio"
          name="live-with"
          :value="home"
          v-model="form.live_with"
        />
        <label
          class="ml-2 uppercase tracking-wider"
          :for="'live-with-' + key"
          >{{ home }}</label
        >
      </div>
      <input
        type="text"
        id="live-with-custom"
        placeholder="lainnya..."
        v-model="form.live_with"
      />

      <!-- distance from school -->
      <label class="form-label" for="address_distance"
        >jarak ke sekolah dari rumah (km)</label
      >
      <p class="text-sm text-gray-800">
        * Cukup menuliskan angka, misal 1 untuk menyatakan 1 km
      </p>
      <p class="text-sm text-gray-800">
        ** Jika menggunakan koma, mohon lakukan pembulatan ke atas. Misal 1,2
        menjadi 2, atau 1,5 menjadi 2, dsb.
      </p>
      <input
        type="text"
        id="address_distance"
        v-model="form.traveling_distance"
      />

      <!-- distance from school -->
      <label class="form-label" for="address_travel_time"
        >waktu tempuh ke sekolah (menit)</label
      >
      <p class="text-sm text-gray-800">
        * Cukup menuliskan angka, misal 15 untuk menyatakan 15 menit
      </p>
      <input
        type="text"
        id="address_travel_time"
        v-model="form.traveling_time"
      />

      <!-- transportation -->
      <span class="form-label">moda transportasi</span>
      <div
        class="mb-1"
        v-for="(vehicle, key) in choices.transportation"
        :key="`transport-${key}`"
      >
        <input
          :id="'transportation-' + key"
          type="radio"
          name="transportation"
          :value="vehicle"
          v-model="form.vehicle"
        />
        <label class="uppercase wider ml-2" :for="'transportation-' + key">{{
          vehicle
        }}</label>
      </div>
      <input
        type="text"
        id="transportation-custom"
        placeholder="lainnya..."
        v-model="form.vehicle"
      />
    </form>

    <template #goto-next-form-trigger>
      <button
        class="button --primary uppercase text-white tracking-wider"
        @click="triggerUpdate('father')"
      >
        <span class="mr-2">Formulir Berikutnya</span>
        <fa-icon icon="arrow-right"></fa-icon>
      </button>
    </template>
  </student-form>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import _clone from "lodash/clone";
import StudentForm from "@/components/Registration/StudentForm.vue";

export default {
  data() {
    return {
      form: {},
      formDefault: {},
      choices: {
        districts: [
          "Pontianak Selatan",
          "Pontianak Timur",
          "Pontianak Barat",
          "Pontianak Tenggara",
          "Pontianak Kota",
          "Pontianak Utara",
          "Kuburaya",
          "Sei. Kakap",
          "Sei. Raya",
        ],
        liveWith: ["Orang Tua", "Wali", "Asrama", "Panti Asuhan"],
        transportation: [
          "Jalan Kaki",
          "Sepeda",
          "Sepeda Motor",
          "Ojek",
          "Mobil",
          "Mobil/Bus Antar Jemput",
          "Angkutan Umum",
        ],
      },
    };
  },
  methods: {
    async triggerUpdate(next = null) {
      await this.updateAddress({ item: this.form, next: next });
    },

    ...mapActions("student", ["updateAddress"]),
  },
  // vm.form undefined?
  watch: {
    selected_form(val) {
      if (val !== "address") {
        this.form = _clone(this.formDefault);
        return;
      }

      this.form = _clone(this.selected_student.address);
    },
  },
  computed: {
    isVisible() {
      return this.form_visibility === true && this.selected_form === "address";
    },
    ...mapGetters("student", [
      "selected_form",
      "form_visibility",
      "selected_student",
    ]),
  },
  components: {
    StudentForm,
  },
  mounted() {
    this.formDefault = _clone(this.form);
  },
  name: "AddressForm",
};
</script>